import * as tslib_1 from "tslib";
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ParameterizedPhoneNumber } from '../models/parameterized-phone-number';
import { CaresService } from './cares.service';
import { getPopupUrl } from '../utils/getPopupUrl';
import logger from './splunk.service';
import * as travelAgentsService from './travel-agents.service';
export var agentUserName;
export var contactId;
var completeCountryList = require('src/assets/country_list.json');
var AmazonconnectService = /** @class */ (function () {
    function AmazonconnectService(caresService) {
        this.caresService = caresService;
        this.isMuted = false;
        this.observableAgent = new BehaviorSubject(this.updatedAgent);
        this.availableAgentStates = {};
        this.observableAgentStates = new BehaviorSubject(this.availableAgentStates);
        this.agentEndpoints = {};
        this.observableAgentEndpoints = new BehaviorSubject(this.agentEndpoints);
        this.observableContactAttributes = new BehaviorSubject(this.getContactAttributes());
        this.observableContact = new ReplaySubject();
        this.connectErrorEvent = new ReplaySubject();
        this.observableInCall = new BehaviorSubject(false);
    }
    AmazonconnectService.prototype.eventChange = function () {
        this.observableAgentStates.next(this.availableAgentStates);
        this.observableAgent.next(this.updatedAgent);
        this.observableAgentEndpoints.next(this.agentEndpoints);
    };
    AmazonconnectService.prototype.updateContactAttributes = function (attrs) {
        this.contactAttributes = attrs || this.getContactAttributes();
        this.observableContactAttributes.next(this.contactAttributes);
    };
    AmazonconnectService.prototype.getPhoneNumber = function () {
        var ccpState = this.getCCPState();
        var getUnfilteredPhoneNumber = ccpState && ccpState.startsWith('third-party') ?
            this.getThirdPartyConnectionNumber() : this.getInitialConnectionNumber();
        return this.keepOnlyNumbers(getUnfilteredPhoneNumber);
    };
    AmazonconnectService.prototype.keepOnlyNumbers = function (str) {
        var regex = /[^0-9]/g;
        return str.replace(regex, '');
    };
    AmazonconnectService.prototype.initConnect = function (containerDiv) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                connect.core.initCCP(containerDiv, {
                    ccpUrl: environment.ccpUrl,
                    absoluteLoginUrl: environment.samlSsoUrl,
                    loginPopup: true,
                    softphone: {
                        allowFramedSoftphone: true
                    }
                });
                connect.agent(function (agent) {
                    _this.agent = agent;
                    _this.updatedAgent = agent;
                    console.log('connect.agent');
                    if (agent) {
                        _this.getAgentQuickConnects();
                        _this.setAgentCallbacks();
                        _this.getAgentStates(agent);
                        agentUserName = _this.getAgentUsername();
                        logger.info('Agent connected');
                    }
                    if ('connectLoginWin' in window) {
                        connectLoginWin.close();
                    }
                });
                connect.contact(function (contact) {
                    _this.contact = contact;
                    console.log('connect.contact');
                    contact.onRefresh(function (newContact) {
                        _this.contact = newContact;
                        _this.observableContact.next(_this.contact);
                        contactId = _this.contact.getContactId();
                        console.log('contact.onRefresh');
                    });
                    contact.onConnecting(function () {
                        _this.updateContactAttributes();
                        _this.observableInCall.next(true);
                        console.log('connect.onConnecting');
                    });
                    contact.onConnected(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var isCallback, result;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.updateContactAttributes();
                                    connect.getLog().info("CARES - Connected with contactID: " + contact.getContactId());
                                    logger.info("Connected with a call", {
                                        queueName: this.getCurrentQueueName(),
                                        originalQueueName: this.getOriginalQueueName(),
                                        initiationMethod: this.getInitiationMethod(),
                                        partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND'
                                    });
                                    if (!this.shouldPopUpBookingUrl()) return [3 /*break*/, 4];
                                    isCallback = null;
                                    if (!(this.getConnectionType() === connect.ConnectionType.OUTBOUND)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.checkIsCustomerCallback()];
                                case 1:
                                    result = _a.sent();
                                    if (result.status && result.status === 200 && result.data.data.pcln.callback.isCustomerCallback) {
                                        logger.info('Pop up booking url for outbound call', {
                                            result: result.data.data.pcln.callback.isCustomerCallback,
                                        });
                                        isCallback = result.data.data.pcln.callback.isCustomerCallback || null;
                                    }
                                    _a.label = 2;
                                case 2: return [4 /*yield*/, this.createPhoneSession(isCallback)];
                                case 3:
                                    _a.sent();
                                    if (this.getConnectionType() !== connect.ConnectionType.OUTBOUND || isCallback) {
                                        this.openBookingUrl();
                                    }
                                    this.sendCallDataToCares(contact);
                                    return [3 /*break*/, 5];
                                case 4:
                                    connect.getLog().info('CARES - Not opening Booking URL of sending CARES data.');
                                    logger.info('Not opening booking url', {
                                        queueName: this.getCurrentQueueName(),
                                        initiationMethod: this.getInitiationMethod(),
                                        contactId: contact.getContactId(),
                                    });
                                    _a.label = 5;
                                case 5:
                                    this.observableInCall.next(true);
                                    console.log('contact.onConnected');
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    contact.onIncoming(function () {
                        _this.updateContactAttributes();
                        _this.observableInCall.next(true);
                        console.log('contact.onIncoming');
                    });
                    contact.onAccepted(function () {
                        _this.updateContactAttributes();
                        connect.getLog().info("CARES - On Accepted with Contact ID " + contact.getContactId());
                        _this.observableInCall.next(true);
                        console.log('contact.onAccepted');
                    });
                    contact.onEnded(function () {
                        console.log('contact.onEnded');
                        _this.contact = null;
                        _this.observableInCall.next(false);
                        _this.observableContact.next(_this.contact);
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    AmazonconnectService.prototype.openBookingUrl = function () {
        var bookingUrl = this.getBookingUrl();
        if (!bookingUrl) {
            logger.error('Booking URL was empty');
            return;
        }
        window.open(bookingUrl);
    };
    AmazonconnectService.prototype.getBookingUrl = function () {
        var agentName = this.getAgentUsername();
        var contactId = this.contact.getContactId();
        var custDialedNumber = this.contactAttributes.CustDialedNumber ? this.contactAttributes.CustDialedNumber.value.replace(/^\+1/, '') : '';
        var currentQueueName = this.getCurrentQueueName();
        var initiationMethod = this.getInitiationMethod();
        /**
         * Set caller Id and queue name for Outbound calls
         * Sandbox Caller ID: +12069712124
         * PROD Caller ID: +18775775782
         * TODO: revisit and improve this part after launch https://priceline.atlassian.net/browse/PPN-64275
         */
        if (initiationMethod === connect.ConnectionType.OUTBOUND) {
            custDialedNumber = environment.production ? '8775775782' : '2069712124';
            if (!currentQueueName) {
                currentQueueName = 'Sales_Hotel';
                logger.warning('Unable to get current queue name, use the default Sales_Hotel', { contactId: contactId });
            }
        }
        var bookingUrl = getPopupUrl(custDialedNumber, agentName, contactId, currentQueueName, initiationMethod);
        connect.getLog().info("CARES - Got booking URL: " + bookingUrl);
        logger.info("Opening booking URL", { bookingUrl: bookingUrl });
        return bookingUrl;
    };
    AmazonconnectService.prototype.shouldPopUpBookingUrl = function () {
        if (!this.contact) {
            connect.getLog().error('Unable to retrieve current contact');
            logger.error('Unable to retrieve current contact');
            return false;
        }
        var connectionType = this.getConnectionType();
        if (!connectionType) {
            connect.getLog().error('Unable to retrieve connectionType');
            logger.error('Unable to retrieve connectionType');
            return false;
        }
        connect.getLog().info("CARES - Connection type is " + connectionType);
        return (this.isQueueNameBelongsTravelAgents() &&
            (connectionType === connect.ConnectionType.INBOUND ||
                connectionType === connect.ConnectionType.AGENT ||
                connectionType === connect.ConnectionType.OUTBOUND));
    };
    AmazonconnectService.prototype.isQueueNameBelongsTravelAgents = function () {
        var currentQueueName = this.getCurrentQueueName();
        var regexForProd = /^(Sales_Hotel|Sales_Package)/;
        var regexForSandbox = /^(Sales_Test|Sales_Test_Package|AIR_Outbound)/;
        return regexForProd.test(currentQueueName) || regexForSandbox.test(currentQueueName);
    };
    AmazonconnectService.prototype.sendCallDataToCares = function (contact) {
        var custDialedNumber = this.contactAttributes.CustDialedNumber ? this.contactAttributes.CustDialedNumber.value : '';
        var caresData = {
            agentId: this.getAgentUsername(),
            contactId: contact.getContactId(),
            didNumber: custDialedNumber
        };
        connect.getLog().info("CARES - Sending Data to CARES. " + JSON.stringify(caresData));
        this.caresService.logSalesCall(caresData)
            .subscribe(function () { return console.log('Sent data to CARES'); }, function (error) { return console.error("Unable to send data to CARES. " + JSON.stringify(error)); });
    };
    AmazonconnectService.prototype.getAgentStates = function (agent) {
        console.log('getting agent states...');
        this.availableAgentStates = agent.getAgentStates();
        this.agentErrorStates = Object.values(connect.AgentErrorStates);
        this.eventChange();
    };
    AmazonconnectService.prototype.getAgentUsername = function () {
        var agent = this.agent;
        if (!agent) {
            console.warn('Unable to get Agent');
            return '';
        }
        var agentConfig = agent.getConfiguration();
        if (!agentConfig) {
            console.warn('Unable to get Agent Configuration');
            return '';
        }
        return agentConfig.username;
    };
    AmazonconnectService.prototype.subscribeToInCallFlag = function (subscription, errorHandler) {
        return this.observableInCall.subscribe(subscription, errorHandler);
    };
    AmazonconnectService.prototype.getAgentQuickConnects = function () {
        var _this = this;
        console.log('getting quickconnect agents...');
        this.updatedAgent.getEndpoints(this.updatedAgent.getAllQueueARNs(), {
            success: function (data) {
                console.log('agent.getEndpoints success');
                console.log(data.addresses);
                _this.agentEndpoints = data.addresses;
                if (_this.getAgentStatus().name !== 'Busy') {
                    _this.agentEndpoints = _.filter(_this.agentEndpoints, function (ep) {
                        return ep.type === 'phone_number';
                    });
                }
                _this.eventChange();
            },
            failure: function (err, data) {
                console.log('agent.getEndpoints failure');
            }
        });
    };
    AmazonconnectService.prototype.setAgentCallbacks = function () {
        var _this = this;
        this.agent.onRefresh(function (agent) {
            _this.updatedAgent = agent;
            _this.getAgentQuickConnects();
            _this.eventChange();
        });
        this.agent.onRoutable(function (agent) {
            // Should start with a clean slate
            console.log('agent.onRoutable');
        });
        this.agent.onNotRoutable(function (agent) {
            console.log('agent.onNotRoutable');
        });
        this.agent.onOffline(function (agent) {
            console.log('agent.onOffline');
        });
        this.agent.onAfterCallWork(function (agent) {
            console.log('agent.onAfterCallWork');
            if (_this.isAgentMuted()) {
                _this.toggleMute();
            }
        });
        this.agent.onStateChange(function (agent) {
            if (agent.newState === 'MissedCallAgent' ||
                agent.newState === 'Default') {
                _this.contact = null;
                _this.observableContact.next(_this.contact);
            }
        });
        this.agent.onError(function (agent) {
            console.log('agent.onError');
        });
    };
    AmazonconnectService.prototype.placeCall = function (numberToCall) {
        var _this = this;
        if (this.updatedAgent) {
            var endpoint = connect.Endpoint.byPhoneNumber(numberToCall);
            this.updatedAgent.connect(endpoint, {
                success: function () {
                    console.log('agent.connect success');
                },
                failure: function () {
                    console.log('agent.connect failure');
                    _this.connectErrorEvent.next('We are unable to complete the call as dialed. Try again, or contact your administrator.');
                }
            });
        }
    };
    AmazonconnectService.prototype.transferCall = function (selectedEndpoint) {
        var _this = this;
        if (this.contact) {
            if (selectedEndpoint) {
                this.contact.addConnection(selectedEndpoint, {
                    success: function () {
                        console.log('contact.addConnection success');
                    },
                    failure: function () {
                        console.log('contact.addConnection failure');
                        _this.connectErrorEvent.next('Quick connect transfer failed. Try again or contact your administrator.');
                    }
                });
            }
        }
    };
    AmazonconnectService.prototype.cancelCall = function () {
        if (this.contact) {
            this.contact.getAgentConnection().destroy({
                success: function () {
                    console.log('connection.destroy success');
                },
                failure: function () {
                    console.log('connection.destroy failure');
                }
            });
        }
    };
    AmazonconnectService.prototype.acceptCall = function () {
        var _this = this;
        if (this.contact) {
            this.contact.accept({
                success: function () {
                    _this.eventChange();
                },
                failure: function () { }
            });
        }
    };
    AmazonconnectService.prototype.holdCall = function () {
        if (this.contact) {
            var conn = this.contact.getActiveInitialConnection();
            if (conn) {
                if (!conn.isOnHold()) {
                    conn.hold({
                        success: function () {
                            console.log('connection.hold success');
                        },
                        failure: function () {
                            console.log('connection.hold failure');
                        }
                    });
                }
                else {
                    conn.resume({
                        success: function () {
                            console.log('connection.resume success');
                        },
                        failure: function () {
                            console.log('connection.resume failure');
                        }
                    });
                }
            }
        }
    };
    AmazonconnectService.prototype.declineCall = function () {
        if (this.contact) {
            var conn = this.contact.getActiveInitialConnection();
            if (conn) {
                conn.destroy({
                    success: function () {
                        console.log('activeInitialConnection.destroy success');
                    },
                    failure: function () {
                        console.log('activeInitialConnection.destroy failure');
                    }
                });
            }
        }
    };
    AmazonconnectService.prototype.setAgentStatus = function (statusToSet) {
        if (this.updatedAgent) {
            var routableState = this.availableAgentStates.filter(function (state) {
                return state.name === statusToSet;
            })[0];
            this.updatedAgent.setState(routableState, {
                success: function () { },
                failure: function () { }
            });
        }
    };
    AmazonconnectService.prototype.getAgentStatus = function () {
        if (this.updatedAgent) {
            return this.updatedAgent.getState();
        }
        else {
            return 'init';
        }
    };
    /*
      This function is intended to determine the CCP state.
      If there is a connection active, it will use the state of the connection, otherwise it will use the agent state.
      There are custom states defined for use when a call involves multiple parties (transfer) seen below
  
      Transfer states:
  
      transfer-connecting ringing
      transfer-2 2nd line active
      transfer-1 1st line active
      transfer-hold all lines held
      transfer-join all lines active
  
    */
    AmazonconnectService.prototype.getCCPState = function () {
        var agentStatus = this.getAgentStatus().name;
        try {
            var activeInitialConnection = this.contact.getActiveInitialConnection();
            var transferConnection = this.contact.getSingleActiveThirdPartyConnection();
            if (transferConnection && this.getAgentStatus().name === 'Busy') {
                var transferStatus = transferConnection.getStatus().type;
                var initialStatus = activeInitialConnection
                    ? activeInitialConnection.getStatus().type
                    : 'disconnected';
                if (transferStatus === 'connecting') {
                    return 'transfer-connecting';
                }
                else if (transferStatus === 'connected' && initialStatus === 'hold') {
                    return 'transfer-2';
                }
                else if (transferStatus === 'hold' && initialStatus === 'connected') {
                    return 'transfer-1';
                }
                else if (transferStatus === 'hold' && initialStatus === 'hold') {
                    return 'transfer-hold';
                }
                else if (transferStatus === 'connected' &&
                    initialStatus === 'connected') {
                    return 'transfer-join';
                }
                else if (transferStatus === 'connected' &&
                    initialStatus === 'disconnected') {
                    return 'third-party-connected';
                }
                else if (transferStatus === 'hold' &&
                    initialStatus === 'disconnected') {
                    return 'third-party-hold';
                }
            }
            else {
                // This check is due to strange behavior with Connect not deleting the contact on a missed call.
                // This is too prevent the Appian button from displaying
                if (agentStatus === 'MissedCallAgent' || agentStatus === 'Default') {
                    return agentStatus;
                }
                else {
                    return activeInitialConnection.getStatus().type;
                }
            }
        }
        catch (e) {
            // TODO: Find a better way to deal with this
            return agentStatus;
        }
    };
    AmazonconnectService.prototype.getInitialConnectionNumber = function () {
        var phoneNumber;
        try {
            phoneNumber = this.contact.getActiveInitialConnection().getEndpoint()
                .phoneNumber;
        }
        catch (e) {
            // TODO: Find a better way to deal with this
        }
        return phoneNumber === undefined ? '' : phoneNumber;
    };
    AmazonconnectService.prototype.getThirdPartyConnectionNumber = function () {
        var phoneNumber;
        try {
            phoneNumber = this.contact
                .getSingleActiveThirdPartyConnection()
                .getEndpoint().phoneNumber;
        }
        catch (e) {
            // TODO: Find a better way to deal with this
        }
        return phoneNumber === undefined ? '' : phoneNumber;
    };
    AmazonconnectService.prototype.getInitialConnectionStateTimer = function () {
        try {
            return this.contact.getActiveInitialConnection().getStatusDuration();
        }
        catch (e) {
            // TODO: Find a better way to deal with this
        }
        return '0';
    };
    AmazonconnectService.prototype.getThirdPartyConnectionStateTimer = function () {
        try {
            return this.contact
                .getSingleActiveThirdPartyConnection()
                .getStatusDuration();
        }
        catch (e) {
            // TODO: Find a better way to deal with this
        }
        return '0';
    };
    AmazonconnectService.prototype.getCCPStateTimer = function () {
        try {
            return this.contact.getActiveInitialConnection().getStatusDuration();
        }
        catch (e) {
            // TODO: Find a better way to deal with this
        }
        return this.updatedAgent ? this.updatedAgent.getStateDuration() : '0';
    };
    AmazonconnectService.prototype.toggleMute = function () {
        this.isMuted ? this.updatedAgent.unmute() : this.updatedAgent.mute();
        this.isMuted = !this.isMuted;
    };
    AmazonconnectService.prototype.isAgentMuted = function () {
        return this.isMuted;
    };
    AmazonconnectService.prototype.toggleActiveConnections = function () {
        this.contact.toggleActiveConnections({
            success: function () {
                console.log('toggled connections');
            },
            failure: function () {
                console.log('failed to toggle connections');
            }
        });
    };
    AmazonconnectService.prototype.conferenceConnections = function () {
        this.contact.conferenceConnections({
            success: function () {
                console.log('joined connections');
            },
            failure: function () {
                console.log('failed to join connections');
            }
        });
    };
    AmazonconnectService.prototype.convertPhoneNumberToEndpoint = function (phoneNumber) {
        return connect.Endpoint.byPhoneNumber(phoneNumber);
    };
    AmazonconnectService.prototype.holdAllConnections = function () {
        var _this = this;
        try {
            this.contact.getActiveInitialConnection().hold();
            // workaround for bug where only one hold will go through at a time
            setTimeout(function () {
                _this.contact.getSingleActiveThirdPartyConnection().hold();
            }, 500);
        }
        catch (e) {
            console.log('failed to hold connections ', e);
        }
    };
    AmazonconnectService.prototype.resumeAllConnections = function () {
        this.conferenceConnections();
    };
    AmazonconnectService.prototype.hangUpInitialConnection = function () {
        try {
            this.contact.getActiveInitialConnection().destroy();
        }
        catch (e) {
            console.log('failed to hang up initial connection: ', e);
        }
    };
    AmazonconnectService.prototype.hangUpThirdPartyConnection = function () {
        try {
            this.contact.getSingleActiveThirdPartyConnection().destroy();
        }
        catch (e) {
            console.log('failed to hang up third party connection: ', e);
        }
    };
    AmazonconnectService.prototype.resumeInitialConnection = function () {
        try {
            this.contact.getActiveInitialConnection().resume();
        }
        catch (e) {
            console.log('failed to resume initial connection: ', e);
        }
    };
    AmazonconnectService.prototype.resumeThirdPartyConnection = function () {
        try {
            this.contact.getSingleActiveThirdPartyConnection().resume();
        }
        catch (e) {
            console.log('failed to resume third party connection: ', e);
        }
    };
    AmazonconnectService.prototype.holdInitialConnection = function () {
        try {
            this.contact.getActiveInitialConnection().hold();
        }
        catch (e) {
            console.log('failed to hold initial connection: ', e);
        }
    };
    AmazonconnectService.prototype.holdThirdPartyConnection = function () {
        try {
            this.contact.getSingleActiveThirdPartyConnection().hold();
        }
        catch (e) {
            console.log('failed to hold initial connection: ', e);
        }
    };
    AmazonconnectService.prototype.sendDigit = function (num) {
        var ccpState = this.getCCPState();
        if (ccpState === 'connected' || ccpState === 'transfer-1') {
            this.contact.getActiveInitialConnection().sendDigits(num);
        }
        else if (ccpState === 'transfer-2' ||
            ccpState === 'third-party-connected') {
            this.contact.getSingleActiveThirdPartyConnection().sendDigits(num);
        }
    };
    AmazonconnectService.prototype.getDialableCountries = function () {
        return _.chain(this.updatedAgent.getDialableCountries())
            .map(function (country) {
            return completeCountryList.find(function (el) {
                return el.isoCode === country;
            });
        })
            .sortBy(['name'])
            .value();
    };
    AmazonconnectService.prototype.getContactAttributes = function () {
        try {
            return this.contact ? this.contact.getAttributes() : {};
        }
        catch (error) {
            console.error("Unable to refresh contact attributes " + error);
            return {};
        }
    };
    AmazonconnectService.prototype.subscribeToContactAttributes = function (subscription, errorHandler) {
        return this.observableContactAttributes.subscribe(subscription, errorHandler);
    };
    AmazonconnectService.prototype.isSoftphone = function () {
        return this.updatedAgent.isSoftphoneEnabled();
    };
    AmazonconnectService.prototype.getAgentDeskPhoneNumberAndCountry = function () {
        var fullExtension = this.updatedAgent.getExtension();
        var dialableCountries = this.getDialableCountries();
        var trimmedPhoneNumber;
        var agentCountry;
        var usa = dialableCountries.find(function (el) {
            return el.isoCode === 'us';
        });
        // If the agent has never set an extension, return empty string and USA
        if (!fullExtension) {
            return new ParameterizedPhoneNumber('', usa);
        }
        this.getDialableCountries().forEach(function (country) {
            if (fullExtension.startsWith(country.code)) {
                trimmedPhoneNumber = fullExtension.substr(country.code.length);
                agentCountry = country;
            }
        });
        if (trimmedPhoneNumber && agentCountry) {
            return new ParameterizedPhoneNumber(trimmedPhoneNumber, agentCountry);
        }
        else {
            throw new Error('Could not parse agent extension');
        }
    };
    AmazonconnectService.prototype.updateAgentConfiguration = function (softphone, phoneNumber) {
        var config = this.updatedAgent.getConfiguration();
        config.softphoneEnabled = softphone;
        if (phoneNumber) {
            config.extension = phoneNumber.country.code + phoneNumber.phoneNumber;
        }
        this.updatedAgent.setConfiguration(config, {
            success: function () {
                console.log('Successfully updated config');
            },
            failure: function () {
                console.log('Failed to update config');
            }
        });
    };
    AmazonconnectService.prototype.downloadAgentLogs = function () {
        connect.rootLogger.download();
    };
    AmazonconnectService.prototype.getAgentUserName = function () {
        return this.updatedAgent.getConfiguration().username;
    };
    AmazonconnectService.prototype.getConnectionType = function () {
        var initConnection = this.contact.getActiveInitialConnection();
        if (!initConnection) {
            connect.getLog().error('Unable to retrieve initial connection');
            return '';
        }
        var connectionType = initConnection.getType();
        connect.getLog().info("CARES - connectionID: " + initConnection.getConnectionId() + ", contactID: " + initConnection.getContactId() + ", connectionType: " + connectionType);
        return connectionType;
    };
    AmazonconnectService.prototype.getCurrentQueueName = function () {
        var currentQueueData = this.contact.getQueue();
        if (!currentQueueData || !currentQueueData.name) {
            connect.getLog().error('Unable to retrieve current queue name');
            logger.error('Unable to retrieve current queue name');
            return '';
        }
        return currentQueueData.name;
    };
    AmazonconnectService.prototype.getMappedQueueName = function () {
        var queueRegex = /_Transfer/;
        return this.getCurrentQueueName().replace(queueRegex, '');
    };
    AmazonconnectService.prototype.getOriginalQueueName = function () {
        return this.contactAttributes.SALES_Xfer ? this.contactAttributes.SALES_Xfer.value : '';
    };
    /**
     * Check if it's a transferred call by checking queue name
     * If original queue name is different than current queue name
     * or if queue name contains 'Transfer' (transfers from customer cares)
     */
    AmazonconnectService.prototype.isTransferredCall = function () {
        var originalQueueName = this.getOriginalQueueName();
        var currentQueueName = this.getCurrentQueueName();
        var isQueueNameDifferent = originalQueueName && (originalQueueName !== currentQueueName);
        var regexToCheckIfContainsTransfer = /_Transfer/;
        var isQueueNameContainsTransfer = regexToCheckIfContainsTransfer.test(currentQueueName);
        return isQueueNameDifferent || isQueueNameContainsTransfer;
    };
    AmazonconnectService.prototype.getInitiationMethod = function () {
        return this.isTransferredCall() ? 'transfer' : this.getConnectionType();
    };
    AmazonconnectService.prototype.getMappedInitiationMethod = function () {
        var initiationMethod = this.getInitiationMethod();
        switch (initiationMethod) {
            case connect.ConnectionType.INBOUND:
                return 'INBOUND';
            case connect.ConnectionType.OUTBOUND:
                return 'OUTBOUND';
            case 'transfer':
                return 'TRANSFER';
            default:
                logger.warning("Unknown initiation method '" + initiationMethod + "' for contact id '" + this.contact.getContactId() + "'. Defaulting to 'INBOUND'.");
                return 'INBOUND';
        }
    };
    AmazonconnectService.prototype.getPhoneSessionParams = function (connectionType, isCallback) {
        switch (connectionType) {
            case connect.ConnectionType.OUTBOUND:
                return this.getOutboundPhoneSessionParams(isCallback);
            case connect.ConnectionType.INBOUND:
            case 'transfer':
                return this.getInboundOrTransferPhoneSessionParams(isCallback);
            default:
                logger.warning("Cannot create phone session params for unknown initiation method '" + connectionType + "' for contact id '" + this.contact.getContactId() + "'.");
                return this.getInboundOrTransferPhoneSessionParams(isCallback);
        }
    };
    AmazonconnectService.prototype.getInboundOrTransferPhoneSessionParams = function (isCallback) {
        var custDialedNumber = this.contactAttributes.CustDialedNumber
            ? this.contactAttributes.CustDialedNumber.value.replace(/^\+1/, '')
            : '';
        return {
            phoneNumber: custDialedNumber,
            contactId: this.contact.getContactId(),
            phoneAgentId: this.getAgentUsername(),
            isDynamic: false,
            ivrQueueName: this.getMappedQueueName(),
            initiationMethod: this.getMappedInitiationMethod(),
            customerPhoneNumber: this.getPhoneNumber(),
            isCallback: isCallback,
            partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND'
        };
    };
    AmazonconnectService.prototype.getOutboundPhoneSessionParams = function (isCallback) {
        var custDialedNumber = environment.production ? '8775775782' : '2069712124';
        return tslib_1.__assign({}, this.getInboundOrTransferPhoneSessionParams(isCallback), { phoneNumber: custDialedNumber, ivrQueueName: this.getCurrentQueueName() || 'Sales_Hotel', partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND' });
    };
    AmazonconnectService.prototype.createPhoneSession = function (isCallback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var phoneSessionParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        phoneSessionParams = this.getPhoneSessionParams(this.getConnectionType(), isCallback);
                        if (!phoneSessionParams.customerPhoneNumber) return [3 /*break*/, 2];
                        return [4 /*yield*/, travelAgentsService.createPhoneSession(phoneSessionParams)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        logger.error('Missing phone number');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AmazonconnectService.prototype.checkIsCustomerCallback = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var agentName, isCustomerCallbackParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        agentName = this.getAgentUsername();
                        isCustomerCallbackParams = {
                            agentName: agentName,
                            phoneNumber: this.getPhoneNumber(),
                        };
                        return [4 /*yield*/, travelAgentsService.isCallbackByPhoneNumber(isCustomerCallbackParams)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return AmazonconnectService;
}());
export { AmazonconnectService };
