import { Component, OnInit, Input } from '@angular/core';
import { UiManagementService } from 'src/app/services/ui-management.service';
import { AmazonconnectService } from 'src/app/services/amazonconnect.service';

@Component({
  selector: 'app-quick-connects',
  templateUrl: './quick-connects.component.html',
  styleUrls: ['./quick-connects.component.scss']
})
export class QuickConnectsComponent implements OnInit {
  @Input()
  quickConnects: any;

  searchInput: string;

  minInputLength = 2;

  constructor(
    private uiManagementService: UiManagementService,
    private amazonConnectService: AmazonconnectService
  ) {}

  ngOnInit() {}

  close() {
    this.uiManagementService.toggleQuickConnects();
  }

  displayDialButton() {
    // Display dial button in search input
    // if the input starts with an optional +
    // followed by at least  digits.
    const regex = /^\+?[0-9]{2,}$/;
    return this.searchInput && regex.test(this.searchInput);
  }

  dialInput(searchInput) {
    if (this.amazonConnectService.getAgentStatus().name === 'Busy') {
      this.amazonConnectService.transferCall(
        this.amazonConnectService.convertPhoneNumberToEndpoint(searchInput)
      );
    } else {
      this.amazonConnectService.placeCall(searchInput);
    }
    this.close();
  }

  dialQuickConnect(qc) {
    if (this.amazonConnectService.getAgentStatus().name === 'Busy') {
      this.amazonConnectService.transferCall(qc);
    } else {
      this.amazonConnectService.placeCall(qc.phoneNumber);
    }
    this.close();
  }
}
