/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quick-connects.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/quick-connect-filter.pipe";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./quick-connects.component";
import * as i6 from "../../services/ui-management.service";
import * as i7 from "../../services/amazonconnect.service";
var styles_QuickConnectsComponent = [i0.styles];
var RenderType_QuickConnectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickConnectsComponent, data: {} });
export { RenderType_QuickConnectsComponent as RenderType_QuickConnectsComponent };
function View_QuickConnectsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "qc__dial-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialInput(_co.searchInput) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Dial"]))], null, null); }
function View_QuickConnectsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "qc__contact-entry"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "qc__dial-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialQuickConnect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Dial"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_QuickConnectsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.QuickConnectFilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "qc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "qc__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "qc__header-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quick connects "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "qc__header-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "qc__searchContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "input", [["class", "qc__search"], ["placeholder", "Enter quick connect or number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchInput = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(14, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConnectsComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "ul", [["class", "qc__contact-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_QuickConnectsComponent_2)), i1.ɵdid(19, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(20, 2)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchInput; _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.displayDialButton(); _ck(_v, 16, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v, 0), _co.quickConnects, _co.searchInput)); _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 14).ngClassValid; var currVal_5 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_QuickConnectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quick-connects", [], null, null, null, View_QuickConnectsComponent_0, RenderType_QuickConnectsComponent)), i1.ɵdid(1, 114688, null, 0, i5.QuickConnectsComponent, [i6.UiManagementService, i7.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuickConnectsComponentNgFactory = i1.ɵccf("app-quick-connects", i5.QuickConnectsComponent, View_QuickConnectsComponent_Host_0, { quickConnects: "quickConnects" }, {}, []);
export { QuickConnectsComponentNgFactory as QuickConnectsComponentNgFactory };
