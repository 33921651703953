import * as tslib_1 from "tslib";
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { agentUserName, contactId } from './amazonconnect.service';
var Logger = /** @class */ (function () {
    function Logger() {
    }
    Logger.prototype.log = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!environment.splunkLogEndpoint || !environment.travelAgentsApiAuth)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(environment.splunkLogEndpoint, {
                                query: "\n          query ForwardLogging($data: JSON!, $appName: NonEmptyString, $appVersion: NonEmptyString) {\n            utility {\n              forwardLogging(data: $data, appName: $appName, appVersion: $appVersion) {\n                message\n                code\n              }\n            }\n          }\n        ",
                                variables: {
                                    data: this.logData,
                                    appName: 'sales-ccp',
                                },
                            }, {
                                timeout: 5000,
                                headers: {
                                    'Content-Type': 'application/json',
                                    'authorization': "Basic " + environment.travelAgentsApiAuth,
                                    'Apollographql-Client-Name': 'sales-ccp',
                                }
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error('Failed to send log to Splunk', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Logger.prototype.formatLogData = function (message, severity, params) {
        this.logData = tslib_1.__assign({ messages: message, severity: severity, environment: environment.production ? 'prod' : 'sandbox' }, (agentUserName && { agent: agentUserName }), (contactId && { contactId: contactId }), (params || {}));
    };
    Logger.prototype.info = function (message, params) {
        this.formatLogData(message, 'info', params);
        this.log();
    };
    Logger.prototype.warning = function (message, params) {
        this.formatLogData(message, 'warning', params);
        this.log();
    };
    Logger.prototype.error = function (message, params) {
        this.formatLogData(message, 'error', params);
        this.log();
    };
    return Logger;
}());
var logger = new Logger();
export default logger;
