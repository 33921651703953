/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./number-dialer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../country-code-select/country-code-select.component.ngfactory";
import * as i3 from "../country-code-select/country-code-select.component";
import * as i4 from "../../services/amazonconnect.service";
import * as i5 from "@angular/forms";
import * as i6 from "./number-dialer.component";
import * as i7 from "../../services/ui-management.service";
var styles_NumberDialerComponent = [i0.styles];
var RenderType_NumberDialerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NumberDialerComponent, data: {} });
export { RenderType_NumberDialerComponent as RenderType_NumberDialerComponent };
export function View_NumberDialerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 80, "div", [["class", "dialer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dialer__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "dialer__header-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dial number "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "dialer__header-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "dialer__input-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-country-code-select", [], null, [[null, "selectedCountryEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedCountryEvent" === en)) {
        var pd_0 = (_co.setSelectedCountry($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CountryCodeSelectComponent_0, i2.RenderType_CountryCodeSelectComponent)), i1.ɵdid(9, 114688, null, 0, i3.CountryCodeSelectComponent, [i4.AmazonconnectService], null, { selectedCountryEvent: "selectedCountryEvent" }), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "dialer__input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "input", [["class", "dialer__number-input"], ["placeholder", "Enter a number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.sendDigitOnKeyboardInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.phoneNumber = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(16, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "dialer__dial-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialNumber() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Dial"])), (_l()(), i1.ɵeld(19, 0, null, null, 61, "div", [["class", "dialer__dial-pad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 14, "div", [["class", "dial-pad__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("1") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 1 "])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("2") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 2 "])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ABC "])), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("3") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3 "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" DEF "])), (_l()(), i1.ɵeld(35, 0, null, null, 15, "div", [["class", "dial-pad__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("4") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4 "])), (_l()(), i1.ɵeld(39, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GHI "])), (_l()(), i1.ɵeld(41, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("5") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 5 "])), (_l()(), i1.ɵeld(44, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" JKL "])), (_l()(), i1.ɵeld(46, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("6") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(47, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 6 "])), (_l()(), i1.ɵeld(49, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" MNO "])), (_l()(), i1.ɵeld(51, 0, null, null, 15, "div", [["class", "dial-pad__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("7") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 7 "])), (_l()(), i1.ɵeld(55, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" PQRS "])), (_l()(), i1.ɵeld(57, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("8") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(58, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 8 "])), (_l()(), i1.ɵeld(60, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TUV "])), (_l()(), i1.ɵeld(62, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("9") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(63, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 9 "])), (_l()(), i1.ɵeld(65, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" WXYZ "])), (_l()(), i1.ɵeld(67, 0, null, null, 13, "div", [["class", "dial-pad__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(68, 0, null, null, 3, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("*") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(69, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" * "])), (_l()(), i1.ɵeld(71, 0, null, null, 0, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(72, 0, null, null, 4, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("0") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(73, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 0 "])), (_l()(), i1.ɵeld(75, 0, null, null, 1, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵeld(77, 0, null, null, 3, "div", [["class", "dial-pad__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNumber("#") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(78, 0, null, null, 1, "div", [["class", "dial-pad__btn-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" # "])), (_l()(), i1.ɵeld(80, 0, null, null, 0, "div", [["class", "dial-pad__btn-text"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); var currVal_7 = _co.phoneNumber; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 16).ngClassValid; var currVal_5 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.phoneNumber.length === 0); _ck(_v, 17, 0, currVal_8); }); }
export function View_NumberDialerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-number-dialer", [], null, null, null, View_NumberDialerComponent_0, RenderType_NumberDialerComponent)), i1.ɵdid(1, 114688, null, 0, i6.NumberDialerComponent, [i7.UiManagementService, i4.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumberDialerComponentNgFactory = i1.ɵccf("app-number-dialer", i6.NumberDialerComponent, View_NumberDialerComponent_Host_0, {}, {}, []);
export { NumberDialerComponentNgFactory as NumberDialerComponentNgFactory };
