import { Injectable } from '@angular/core';
import { BehaviorSubject ,  ReplaySubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ParameterizedPhoneNumber } from '../models/parameterized-phone-number';
import { Country } from '../models/country';
import { CaresService } from './cares.service';
import { getPopupUrl } from '../utils/getPopupUrl';
import logger from './splunk.service';
import * as travelAgentsService from './travel-agents.service';

declare var connect;
declare var connectLoginWin;
export let agentUserName;
export let contactId;

const completeCountryList = require('src/assets/country_list.json');

export interface ContactAttribute {
  name: string;
  value: string;
}

export interface ContactAttributes {
  CustDialedNumber?: ContactAttribute;
  secureivr?: ContactAttribute;
  SALES_Xfer?: ContactAttribute;
  PARTNERCODE?: ContactAttribute;
}

@Injectable()
export class AmazonconnectService {
  private updatedAgent: any;

  agentErrorStates: string[];

  private agent: any;
  observableAgent: BehaviorSubject<any>;

  private availableAgentStates: any;
  observableAgentStates: BehaviorSubject<any>;

  private agentEndpoints: any;
  observableAgentEndpoints: BehaviorSubject<any>;

  contactAttributes: ContactAttributes;
  observableContactAttributes: BehaviorSubject<ContactAttributes>;

  private contact: any;
  observableContact: ReplaySubject<any>;

  private isMuted = false;

  connectErrorEvent: ReplaySubject<any>;

  observableInCall: BehaviorSubject<boolean>;

  constructor(private caresService: CaresService) {
    this.observableAgent = new BehaviorSubject<any>(this.updatedAgent);
    this.availableAgentStates = {};
    this.observableAgentStates = new BehaviorSubject<any>(
      this.availableAgentStates
    );
    this.agentEndpoints = {};
    this.observableAgentEndpoints = new BehaviorSubject<any>(
      this.agentEndpoints
    );
    this.observableContactAttributes = new BehaviorSubject<ContactAttributes>(
      this.getContactAttributes()
    );
    this.observableContact = new ReplaySubject<any>();
    this.connectErrorEvent = new ReplaySubject<any>();
    this.observableInCall = new BehaviorSubject<boolean>(
      false
    );
  }

  eventChange() {
    this.observableAgentStates.next(this.availableAgentStates);
    this.observableAgent.next(this.updatedAgent);
    this.observableAgentEndpoints.next(this.agentEndpoints);
  }

  updateContactAttributes(attrs?: any): void {
    this.contactAttributes = attrs || this.getContactAttributes();
    this.observableContactAttributes.next(this.contactAttributes);
  }

  getPhoneNumber(): string | null {
    const ccpState = this.getCCPState();
    const getUnfilteredPhoneNumber = ccpState && ccpState.startsWith('third-party') ?
      this.getThirdPartyConnectionNumber() : this.getInitialConnectionNumber();
    return this.keepOnlyNumbers(getUnfilteredPhoneNumber)
  }

  keepOnlyNumbers(str: string): string {
    const regex = /[^0-9]/g;
    return str.replace(regex, '');
  }

  async initConnect(containerDiv) {
    connect.core.initCCP(containerDiv, {
      ccpUrl: environment.ccpUrl,
      absoluteLoginUrl: environment.samlSsoUrl,
      loginPopup: true,
      softphone: {
        allowFramedSoftphone: true
      }
    });

    connect.agent(agent => {
      this.agent = agent;
      this.updatedAgent = agent;

      console.log('connect.agent');

      if (agent) {
        this.getAgentQuickConnects();
        this.setAgentCallbacks();
        this.getAgentStates(agent);
        agentUserName = this.getAgentUsername();
        logger.info('Agent connected')
      }

      if ('connectLoginWin' in window) {
        connectLoginWin.close();
      }
    });

    connect.contact(contact => {
      this.contact = contact;

      console.log('connect.contact');

      contact.onRefresh(newContact => {
        this.contact = newContact;
        this.observableContact.next(this.contact);
        contactId = this.contact.getContactId();
        console.log('contact.onRefresh');
      });

      contact.onConnecting(() => {
        this.updateContactAttributes();
        this.observableInCall.next(true);
        console.log('connect.onConnecting');
      });

      contact.onConnected(async () => {
        this.updateContactAttributes();

        connect.getLog().info(`CARES - Connected with contactID: ${contact.getContactId()}`);
        logger.info(`Connected with a call`, {
          queueName: this.getCurrentQueueName(),
          originalQueueName: this.getOriginalQueueName(),
          initiationMethod: this.getInitiationMethod(),
          partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND'
        });

        if (this.shouldPopUpBookingUrl()) {
          let isCallback = null;
          if (this.getConnectionType() === connect.ConnectionType.OUTBOUND) {
            const result = await this.checkIsCustomerCallback();
            if (result.status && result.status === 200 && result.data.data.pcln.callback.isCustomerCallback) {
              logger.info('Pop up booking url for outbound call', {
                result: result.data.data.pcln.callback.isCustomerCallback,
              });
              isCallback = result.data.data.pcln.callback.isCustomerCallback || null;
            }
          }

          await this.createPhoneSession(isCallback);

          if (this.getConnectionType() !== connect.ConnectionType.OUTBOUND || isCallback) {
            this.openBookingUrl();
          }

          this.sendCallDataToCares(contact);
        } else {
          connect.getLog().info('CARES - Not opening Booking URL of sending CARES data.');
          logger.info('Not opening booking url', {
            queueName: this.getCurrentQueueName(),
            initiationMethod: this.getInitiationMethod(),
            contactId: contact.getContactId(),
          });
        }

        this.observableInCall.next(true);
        console.log('contact.onConnected');
      });

      contact.onIncoming(() => {
        this.updateContactAttributes();
        this.observableInCall.next(true);
        console.log('contact.onIncoming');
      });

      contact.onAccepted(() => {
        this.updateContactAttributes();
        connect.getLog().info(`CARES - On Accepted with Contact ID ${contact.getContactId()}`);
        this.observableInCall.next(true);
        console.log('contact.onAccepted');
      });

      contact.onEnded(() => {
        console.log('contact.onEnded');

        this.contact = null;
        this.observableInCall.next(false);
        this.observableContact.next(this.contact);
      });
    });
  }

  openBookingUrl() {
    const bookingUrl = this.getBookingUrl();
    if (!bookingUrl) {
      logger.error('Booking URL was empty');
      return;
    }
    window.open(bookingUrl);
  }

  getBookingUrl(): string {
    const agentName = this.getAgentUsername();
    const contactId = this.contact.getContactId();
    let custDialedNumber = this.contactAttributes.CustDialedNumber ? this.contactAttributes.CustDialedNumber.value.replace(/^\+1/, '') : '';
    let currentQueueName = this.getCurrentQueueName();
    const initiationMethod = this.getInitiationMethod();

    /**
     * Set caller Id and queue name for Outbound calls
     * Sandbox Caller ID: +12069712124
     * PROD Caller ID: +18775775782
     * TODO: revisit and improve this part after launch https://priceline.atlassian.net/browse/PPN-64275
     */
    if (initiationMethod === connect.ConnectionType.OUTBOUND) {
      custDialedNumber = environment.production ? '8775775782' : '2069712124';
      if (!currentQueueName) {
        currentQueueName = 'Sales_Hotel';
        logger.warning('Unable to get current queue name, use the default Sales_Hotel', { contactId: contactId });
      }
    }

    const bookingUrl = getPopupUrl(custDialedNumber, agentName, contactId, currentQueueName, initiationMethod);
    connect.getLog().info(`CARES - Got booking URL: ${bookingUrl}`);
    logger.info(`Opening booking URL`, { bookingUrl: bookingUrl });
    return bookingUrl;
  }

  shouldPopUpBookingUrl(): boolean {
    if (!this.contact) {
      connect.getLog().error('Unable to retrieve current contact');
      logger.error('Unable to retrieve current contact');
      return false;
    }

    const connectionType = this.getConnectionType();

    if (!connectionType) {
      connect.getLog().error('Unable to retrieve connectionType');
      logger.error('Unable to retrieve connectionType');
      return false;
    }

    connect.getLog().info(`CARES - Connection type is ${connectionType}`);
    
    return (
      this.isQueueNameBelongsTravelAgents() &&
      (
        connectionType === connect.ConnectionType.INBOUND ||
        connectionType === connect.ConnectionType.AGENT ||
        connectionType === connect.ConnectionType.OUTBOUND
      )
    );
  }

  isQueueNameBelongsTravelAgents(): boolean {
    const currentQueueName = this.getCurrentQueueName()
    const regexForProd = /^(Sales_Hotel|Sales_Package)/;
    const regexForSandbox = /^(Sales_Test|Sales_Test_Package|AIR_Outbound)/;
    return regexForProd.test(currentQueueName) || regexForSandbox.test(currentQueueName)
  }

  sendCallDataToCares(contact) {
    const custDialedNumber = this.contactAttributes.CustDialedNumber ? this.contactAttributes.CustDialedNumber.value : '';

    const caresData = {
      agentId: this.getAgentUsername(),
      contactId: contact.getContactId(),
      didNumber: custDialedNumber
    };

    connect.getLog().info(`CARES - Sending Data to CARES. ${JSON.stringify(caresData)}`);

    this.caresService.logSalesCall(caresData)
    .subscribe(
      () => console.log('Sent data to CARES'),
      (error) => console.error(`Unable to send data to CARES. ${JSON.stringify(error)}`)
    );
  }

  getAgentStates(agent) {
    console.log('getting agent states...');
    this.availableAgentStates = agent.getAgentStates();
    this.agentErrorStates = Object.values(connect.AgentErrorStates);
    this.eventChange();
  }

  getAgentUsername(): any {
    const agent =  this.agent;
    if (!agent) {
      console.warn('Unable to get Agent');
      return '';
    }

    const agentConfig = agent.getConfiguration();
    if (!agentConfig) {
      console.warn('Unable to get Agent Configuration');
      return '';
    }

    return agentConfig.username;
  }

  subscribeToInCallFlag(subscription: any, errorHandler?: any): Subscription {
    return this.observableInCall.subscribe(subscription, errorHandler);
  }

  getAgentQuickConnects() {
    console.log('getting quickconnect agents...');
    this.updatedAgent.getEndpoints(this.updatedAgent.getAllQueueARNs(), {
      success: data => {
        console.log('agent.getEndpoints success');
        console.log(data.addresses);

        this.agentEndpoints = data.addresses;

        if (this.getAgentStatus().name !== 'Busy') {
          this.agentEndpoints = _.filter(this.agentEndpoints, ep => {
            return ep.type === 'phone_number';
          });
        }

        this.eventChange();
      },
      failure: (err, data) => {
        console.log('agent.getEndpoints failure');
      }
    });
  }

  setAgentCallbacks() {
    this.agent.onRefresh(agent => {
      this.updatedAgent = agent;
      this.getAgentQuickConnects();
      this.eventChange();
    });

    this.agent.onRoutable(agent => {
      // Should start with a clean slate
      console.log('agent.onRoutable');
    });

    this.agent.onNotRoutable(agent => {
      console.log('agent.onNotRoutable');
    });

    this.agent.onOffline(agent => {
      console.log('agent.onOffline');
    });

    this.agent.onAfterCallWork(agent => {
      console.log('agent.onAfterCallWork');

      if (this.isAgentMuted()) {
        this.toggleMute();
      }
    });

    this.agent.onStateChange(agent => {
      if (
        agent.newState === 'MissedCallAgent' ||
        agent.newState === 'Default'
      ) {
        this.contact = null;
        this.observableContact.next(this.contact);
      }
    });

    this.agent.onError(agent => {
      console.log('agent.onError');
    });
  }

  placeCall(numberToCall) {
    if (this.updatedAgent) {
      const endpoint = connect.Endpoint.byPhoneNumber(numberToCall);
      this.updatedAgent.connect(
        endpoint,
        {
          success: () => {
            console.log('agent.connect success');
          },
          failure: () => {
            console.log('agent.connect failure');
            this.connectErrorEvent.next(
              'We are unable to complete the call as dialed. Try again, or contact your administrator.'
            );
          }
        }
      );
    }
  }

  transferCall(selectedEndpoint) {
    if (this.contact) {
      if (selectedEndpoint) {
        this.contact.addConnection(selectedEndpoint, {
          success: () => {
            console.log('contact.addConnection success');
          },
          failure: () => {
            console.log('contact.addConnection failure');
            this.connectErrorEvent.next(
              'Quick connect transfer failed. Try again or contact your administrator.'
            );
          }
        });
      }
    }
  }

  cancelCall() {
    if (this.contact) {
      this.contact.getAgentConnection().destroy({
        success: () => {
          console.log('connection.destroy success');
        },
        failure: () => {
          console.log('connection.destroy failure');
        }
      });
    }
  }

  acceptCall() {
    if (this.contact) {
      this.contact.accept({
        success: () => {
          this.eventChange();
        },
        failure: () => { }
      });
    }
  }

  holdCall() {
    if (this.contact) {
      const conn = this.contact.getActiveInitialConnection();

      if (conn) {
        if (!conn.isOnHold()) {
          conn.hold({
            success: () => {
              console.log('connection.hold success');
            },
            failure: () => {
              console.log('connection.hold failure');
            }
          });
        } else {
          conn.resume({
            success: () => {
              console.log('connection.resume success');
            },
            failure: () => {
              console.log('connection.resume failure');
            }
          });
        }
      }
    }
  }

  declineCall() {
    if (this.contact) {
      const conn = this.contact.getActiveInitialConnection();

      if (conn) {
        conn.destroy({
          success: () => {
            console.log('activeInitialConnection.destroy success');
          },
          failure: () => {
            console.log('activeInitialConnection.destroy failure');
          }
        });
      }
    }
  }

  setAgentStatus(statusToSet) {
    if (this.updatedAgent) {
      const routableState = this.availableAgentStates.filter(function (state) {
        return state.name === statusToSet;
      })[0];

      this.updatedAgent.setState(routableState, {
        success: () => { },
        failure: () => { }
      });
    }
  }

  getAgentStatus() {
    if (this.updatedAgent) {
      return this.updatedAgent.getState();
    } else { return 'init'; }
  }

  /*
    This function is intended to determine the CCP state.
    If there is a connection active, it will use the state of the connection, otherwise it will use the agent state.
    There are custom states defined for use when a call involves multiple parties (transfer) seen below

    Transfer states:

    transfer-connecting ringing
    transfer-2 2nd line active
    transfer-1 1st line active
    transfer-hold all lines held
    transfer-join all lines active

  */
  getCCPState() {
    const agentStatus = this.getAgentStatus().name;

    try {
      const activeInitialConnection = this.contact.getActiveInitialConnection();
      const transferConnection = this.contact.getSingleActiveThirdPartyConnection();

      if (transferConnection && this.getAgentStatus().name === 'Busy') {
        const transferStatus = transferConnection.getStatus().type;
        const initialStatus = activeInitialConnection
          ? activeInitialConnection.getStatus().type
          : 'disconnected';

        if (transferStatus === 'connecting') {
          return 'transfer-connecting';
        } else if (transferStatus === 'connected' && initialStatus === 'hold') {
          return 'transfer-2';
        } else if (transferStatus === 'hold' && initialStatus === 'connected') {
          return 'transfer-1';
        } else if (transferStatus === 'hold' && initialStatus === 'hold') {
          return 'transfer-hold';
        } else if (
          transferStatus === 'connected' &&
          initialStatus === 'connected'
        ) {
          return 'transfer-join';
        } else if (
          transferStatus === 'connected' &&
          initialStatus === 'disconnected'
        ) {
          return 'third-party-connected';
        } else if (
          transferStatus === 'hold' &&
          initialStatus === 'disconnected'
        ) {
          return 'third-party-hold';
        }
      } else {
        // This check is due to strange behavior with Connect not deleting the contact on a missed call.
        // This is too prevent the Appian button from displaying
        if (agentStatus === 'MissedCallAgent' || agentStatus === 'Default') {
          return agentStatus;
        } else {
          return activeInitialConnection.getStatus().type;
        }
      }
    } catch (e) {
      // TODO: Find a better way to deal with this
      return agentStatus;
    }
  }

  getInitialConnectionNumber(): string {
    let phoneNumber;

    try {
      phoneNumber = this.contact.getActiveInitialConnection().getEndpoint()
        .phoneNumber;
    } catch (e) {
      // TODO: Find a better way to deal with this
    }

    return phoneNumber === undefined ? '' : phoneNumber;
  }

  getThirdPartyConnectionNumber(): string {
    let phoneNumber;

    try {
      phoneNumber = this.contact
        .getSingleActiveThirdPartyConnection()
        .getEndpoint().phoneNumber;
    } catch (e) {
      // TODO: Find a better way to deal with this
    }

    return phoneNumber === undefined ? '' : phoneNumber;
  }

  getInitialConnectionStateTimer(): string {
    try {
      return this.contact.getActiveInitialConnection().getStatusDuration();
    } catch (e) {
      // TODO: Find a better way to deal with this
    }

    return '0';
  }

  getThirdPartyConnectionStateTimer(): string {
    try {
      return this.contact
        .getSingleActiveThirdPartyConnection()
        .getStatusDuration();
    } catch (e) {
      // TODO: Find a better way to deal with this
    }

    return '0';
  }

  getCCPStateTimer() {
    try {
      return this.contact.getActiveInitialConnection().getStatusDuration();
    } catch (e) {
      // TODO: Find a better way to deal with this
    }

    return this.updatedAgent ? this.updatedAgent.getStateDuration() : '0';
  }

  toggleMute() {
    this.isMuted ? this.updatedAgent.unmute() : this.updatedAgent.mute();

    this.isMuted = !this.isMuted;
  }

  isAgentMuted() {
    return this.isMuted;
  }

  toggleActiveConnections() {
    this.contact.toggleActiveConnections({
      success: () => {
        console.log('toggled connections');
      },
      failure: () => {
        console.log('failed to toggle connections');
      }
    });
  }

  conferenceConnections() {
    this.contact.conferenceConnections({
      success: () => {
        console.log('joined connections');
      },
      failure: () => {
        console.log('failed to join connections');
      }
    });
  }

  convertPhoneNumberToEndpoint(phoneNumber) {
    return connect.Endpoint.byPhoneNumber(phoneNumber);
  }

  holdAllConnections() {
    try {
      this.contact.getActiveInitialConnection().hold();

      // workaround for bug where only one hold will go through at a time
      setTimeout(() => {
        this.contact.getSingleActiveThirdPartyConnection().hold();
      }, 500);
    } catch (e) {
      console.log('failed to hold connections ', e);
    }
  }

  resumeAllConnections() {
    this.conferenceConnections();
  }

  hangUpInitialConnection() {
    try {
      this.contact.getActiveInitialConnection().destroy();
    } catch (e) {
      console.log('failed to hang up initial connection: ', e);
    }
  }

  hangUpThirdPartyConnection() {
    try {
      this.contact.getSingleActiveThirdPartyConnection().destroy();
    } catch (e) {
      console.log('failed to hang up third party connection: ', e);
    }
  }

  resumeInitialConnection() {
    try {
      this.contact.getActiveInitialConnection().resume();
    } catch (e) {
      console.log('failed to resume initial connection: ', e);
    }
  }

  resumeThirdPartyConnection() {
    try {
      this.contact.getSingleActiveThirdPartyConnection().resume();
    } catch (e) {
      console.log('failed to resume third party connection: ', e);
    }
  }

  holdInitialConnection() {
    try {
      this.contact.getActiveInitialConnection().hold();
    } catch (e) {
      console.log('failed to hold initial connection: ', e);
    }
  }

  holdThirdPartyConnection() {
    try {
      this.contact.getSingleActiveThirdPartyConnection().hold();
    } catch (e) {
      console.log('failed to hold initial connection: ', e);
    }
  }

  sendDigit(num: string) {
    const ccpState = this.getCCPState();

    if (ccpState === 'connected' || ccpState === 'transfer-1') {
      this.contact.getActiveInitialConnection().sendDigits(num);
    } else if (
      ccpState === 'transfer-2' ||
      ccpState === 'third-party-connected'
    ) {
      this.contact.getSingleActiveThirdPartyConnection().sendDigits(num);
    }
  }

  getDialableCountries(): Country[] {
    return _.chain(this.updatedAgent.getDialableCountries())
      .map(country => {
        return completeCountryList.find(el => {
          return el.isoCode === country;
        });
      })
      .sortBy(['name'])
      .value();
  }

  getContactAttributes(): ContactAttributes {
    try {
      return this.contact ? this.contact.getAttributes() : {};
    } catch (error) {
      console.error(`Unable to refresh contact attributes ${error}`);
      return {};
    }
  }

  subscribeToContactAttributes(subscription: any, errorHandler?: any): Subscription {
    return this.observableContactAttributes.subscribe(subscription, errorHandler);
  }

  isSoftphone(): boolean {
    return this.updatedAgent.isSoftphoneEnabled();
  }

  getAgentDeskPhoneNumberAndCountry(): ParameterizedPhoneNumber {
    const fullExtension: string = this.updatedAgent.getExtension();
    const dialableCountries = this.getDialableCountries();
    let trimmedPhoneNumber;
    let agentCountry;

    const usa: Country = dialableCountries.find(el => {
      return el.isoCode === 'us';
    });

    // If the agent has never set an extension, return empty string and USA
    if (!fullExtension) {
      return new ParameterizedPhoneNumber('', usa);
    }

    this.getDialableCountries().forEach(country => {
      if (fullExtension.startsWith(country.code)) {
        trimmedPhoneNumber = fullExtension.substr(country.code.length);
        agentCountry = country;
      }
    });

    if (trimmedPhoneNumber && agentCountry) {
      return new ParameterizedPhoneNumber(trimmedPhoneNumber, agentCountry);
    } else {
      throw new Error('Could not parse agent extension');
    }
  }

  updateAgentConfiguration(
    softphone: boolean,
    phoneNumber?: ParameterizedPhoneNumber
  ) {
    const config = this.updatedAgent.getConfiguration();

    config.softphoneEnabled = softphone;

    if (phoneNumber) {
      config.extension = phoneNumber.country.code + phoneNumber.phoneNumber;
    }

    this.updatedAgent.setConfiguration(config, {
      success: function () {
        console.log('Successfully updated config');
      },
      failure: function () {
        console.log('Failed to update config');
      }
    });
  }

  downloadAgentLogs() {
    connect.rootLogger.download();
  }

  getAgentUserName() {
    return this.updatedAgent.getConfiguration().username;
  }

  getConnectionType(): string {
    const initConnection = this.contact.getActiveInitialConnection();
    if (!initConnection) {
      connect.getLog().error('Unable to retrieve initial connection');
      return '';
    }
    const connectionType = initConnection.getType();
    connect.getLog().info(`CARES - connectionID: ${initConnection.getConnectionId()}, contactID: ${initConnection.getContactId()}, connectionType: ${connectionType}`);
    return connectionType;
  }

  getCurrentQueueName(): string {
    const currentQueueData = this.contact.getQueue()
    if (!currentQueueData || !currentQueueData.name) {
      connect.getLog().error('Unable to retrieve current queue name');
      logger.error('Unable to retrieve current queue name');
      return '';
    }
    return currentQueueData.name
  }

  getMappedQueueName(): string {
    const queueRegex = /_Transfer/
    return this.getCurrentQueueName().replace(queueRegex, '')
  }

  getOriginalQueueName(): string {
    return this.contactAttributes.SALES_Xfer ? this.contactAttributes.SALES_Xfer.value : '';
  }

  /**
   * Check if it's a transferred call by checking queue name
   * If original queue name is different than current queue name
   * or if queue name contains 'Transfer' (transfers from customer cares)
   */
  isTransferredCall(): boolean {
    const originalQueueName = this.getOriginalQueueName();
    const currentQueueName = this.getCurrentQueueName();
    const isQueueNameDifferent = originalQueueName && (originalQueueName !== currentQueueName);

    const regexToCheckIfContainsTransfer = /_Transfer/;
    const isQueueNameContainsTransfer = regexToCheckIfContainsTransfer.test(currentQueueName);
    
    return isQueueNameDifferent || isQueueNameContainsTransfer;
  }

  getInitiationMethod(): string {
    return this.isTransferredCall() ? 'transfer' : this.getConnectionType();
  }

  getMappedInitiationMethod(): 'INBOUND' | 'OUTBOUND' | 'TRANSFER' {
    const initiationMethod = this.getInitiationMethod()

    switch (initiationMethod) {
      case connect.ConnectionType.INBOUND:
        return 'INBOUND';
      case connect.ConnectionType.OUTBOUND:
        return 'OUTBOUND';
      case 'transfer':
        return 'TRANSFER';
      default: 
        logger.warning(`Unknown initiation method '${initiationMethod}' for contact id '${this.contact.getContactId()}'. Defaulting to 'INBOUND'.`);
        return 'INBOUND';
    }
  }

  getPhoneSessionParams(connectionType: string, isCallback: boolean | null): travelAgentsService.ICreatePhoneSessionParams {
    switch (connectionType) {
      case connect.ConnectionType.OUTBOUND:
        return this.getOutboundPhoneSessionParams(isCallback);
      case connect.ConnectionType.INBOUND:
      case 'transfer':
        return this.getInboundOrTransferPhoneSessionParams(isCallback);
      default: 
        logger.warning(`Cannot create phone session params for unknown initiation method '${connectionType}' for contact id '${this.contact.getContactId()}'.`);
        return this.getInboundOrTransferPhoneSessionParams(isCallback);
    }
  }

  getInboundOrTransferPhoneSessionParams(isCallback: boolean | null): travelAgentsService.ICreatePhoneSessionParams {
    const custDialedNumber = this.contactAttributes.CustDialedNumber 
      ? this.contactAttributes.CustDialedNumber.value.replace(/^\+1/, '') 
      : '';
    return {
      phoneNumber: custDialedNumber,
      contactId: this.contact.getContactId(),
      phoneAgentId: this.getAgentUsername(),
      isDynamic: false,
      ivrQueueName: this.getMappedQueueName(),
      initiationMethod: this.getMappedInitiationMethod(),
      customerPhoneNumber: this.getPhoneNumber(),
      isCallback: isCallback,
      partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND'
    };
  }

  getOutboundPhoneSessionParams(isCallback: boolean | null): travelAgentsService.ICreatePhoneSessionParams {
    const custDialedNumber = environment.production ? '8775775782' : '2069712124';
    return {
      ...this.getInboundOrTransferPhoneSessionParams(isCallback),
      phoneNumber: custDialedNumber,
      ivrQueueName: this.getCurrentQueueName() || 'Sales_Hotel',
      partnerCode: this.contactAttributes.PARTNERCODE ? this.contactAttributes.PARTNERCODE.value : 'NOT_FOUND'
    };
  }

  async createPhoneSession(isCallback: boolean | null): Promise<void> {
    const phoneSessionParams = this.getPhoneSessionParams(this.getConnectionType(), isCallback);
    if (phoneSessionParams.customerPhoneNumber) {
      await travelAgentsService.createPhoneSession(phoneSessionParams);
    } else {
      logger.error('Missing phone number');
    }
  }

  async checkIsCustomerCallback(): Promise<travelAgentsService.IIsCustomerCallbackData> {
    const agentName = this.getAgentUsername();
    const isCustomerCallbackParams: travelAgentsService.IIsCustomerCallbackParams = {
      agentName: agentName,
      phoneNumber: this.getPhoneNumber(),
    };
    return await travelAgentsService.isCallbackByPhoneNumber(isCustomerCallbackParams);
  }
}
