/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-buttons-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bottom-buttons-panel.component";
import * as i4 from "../../services/amazonconnect.service";
var styles_BottomButtonsPanelComponent = [i0.styles];
var RenderType_BottomButtonsPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomButtonsPanelComponent, data: {} });
export { RenderType_BottomButtonsPanelComponent as RenderType_BottomButtonsPanelComponent };
function View_BottomButtonsPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bottom-buttons-panel__avail-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setStatusAvailable() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Set To Available "]))], null, null); }
function View_BottomButtonsPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "bottom-buttons-panel__inbound"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "bottom-buttons-panel__inbound-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["call"])), (_l()(), i1.ɵted(-1, null, ["\u00A0 Accept call"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "bottom-buttons-panel__inbound-reject"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rejectCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ring_volume"])), (_l()(), i1.ɵted(-1, null, ["\u00A0 Reject call"]))], null, null); }
function View_BottomButtonsPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ring_volume"])), (_l()(), i1.ɵted(-1, null, ["\u00A0 End Call"]))], null, null); }
function View_BottomButtonsPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["phone_forwarded"])), (_l()(), i1.ɵted(-1, null, ["\u00A0 Leave Call"]))], null, null); }
function View_BottomButtonsPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "bottom-buttons-panel__end-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.endCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomButtonsPanelComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomButtonsPanelComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMultiPartyCall(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMultiPartyCall(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BottomButtonsPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "bottom-buttons-panel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomButtonsPanelComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomButtonsPanelComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomButtonsPanelComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayAvailableBtn(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayInboundCallBtns(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.displayEndBtn(); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_BottomButtonsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-buttons-panel", [], null, null, null, View_BottomButtonsPanelComponent_0, RenderType_BottomButtonsPanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.BottomButtonsPanelComponent, [i4.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomButtonsPanelComponentNgFactory = i1.ɵccf("app-bottom-buttons-panel", i3.BottomButtonsPanelComponent, View_BottomButtonsPanelComponent_Host_0, { agentStatus: "agentStatus" }, {}, []);
export { BottomButtonsPanelComponentNgFactory as BottomButtonsPanelComponentNgFactory };
