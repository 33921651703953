<div class="qc">
  <div class="qc__header">
    <div class="qc__header-text">
      Quick connects
    </div>
    <div class="qc__header-close" (click)="close()">
      <i class="material-icons">close</i>
    </div>
  </div>

  <div class="qc__searchContainer">
    <input class="qc__search" placeholder="Enter quick connect or number" [(ngModel)]="searchInput" />
    <button class="qc__dial-btn" *ngIf="displayDialButton()" (click)="dialInput(searchInput)">Dial</button>
  </div>
  <ul class="qc__contact-list">
    <li class="qc__contact-entry" *ngFor="let qc of quickConnects | quickConnectFilter : searchInput">{{qc.name}}
      <button class="qc__dial-btn" (click)="dialQuickConnect(qc)">Dial</button></li>
  </ul>
</div>