/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./state-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/agent-status.pipe";
import * as i4 from "../../services/amazonconnect.service";
import * as i5 from "../../pipes/millis-to-time.pipe";
import * as i6 from "./state-panel.component";
var styles_StatePanelComponent = [i0.styles];
var RenderType_StatePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatePanelComponent, data: {} });
export { RenderType_StatePanelComponent as RenderType_StatePanelComponent };
function View_StatePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Booking"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getBookingUrl(), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_StatePanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "state-panel__muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MUTED "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"]))], null, null); }
function View_StatePanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "state-panel__contact-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCallStatusText(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getSingleConnectionNumber(); _ck(_v, 4, 0, currVal_1); }); }
function View_StatePanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "state-panel__timer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time Elapsed"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 1), _co.stateTimer)); _ck(_v, 4, 0, currVal_0); }); }
function View_StatePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "state-panel__single-connection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "state-panel__status"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "state-panel__attrs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "state-panel__contact-info-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.inCall && _co.shouldPopUpBookingUrl); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.amazonConnectService.isAgentMuted(); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.displayPhoneNumber(); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.displayTimer(); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.agentStatus)); _ck(_v, 2, 0, currVal_0); }); }
function View_StatePanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "conn-row__btn conn-row__btn--resume"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumeInitialConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["play_arrow"]))], null, null); }
function View_StatePanelComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "conn-row__btn conn-row__btn--hold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdInitialConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"]))], null, null); }
function View_StatePanelComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "conn-row__btn conn-row__btn--resume"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumeThirdPartyConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["play_arrow"]))], null, null); }
function View_StatePanelComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "conn-row__btn conn-row__btn--hold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdThirdPartyConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"]))], null, null); }
function View_StatePanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [["class", "state-panel__multi-connection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "state-panel__conn-row state-panel__conn-row-top"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "conn-row__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hangUpInitialConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "conn-row__timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵppd(18, 1), (_l()(), i1.ɵeld(19, 0, null, null, 17, "div", [["class", "state-panel__conn-row"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_9)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_10)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 2, "span", [["class", "conn-row__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hangUpThirdPartyConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(32, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(33, null, ["", " "])), (_l()(), i1.ɵeld(34, 0, null, null, 2, "span", [["class", "conn-row__timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), i1.ɵppd(36, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "state-panel__conn-row state-panel__conn-row-top"; var currVal_1 = _co.getConnectionStyle("initial"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.isTransferHold(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isTransferJoined(); _ck(_v, 10, 0, currVal_4); var currVal_7 = "state-panel__conn-row"; var currVal_8 = _co.getConnectionStyle("thirdParty"); _ck(_v, 21, 0, currVal_7, currVal_8); var currVal_10 = _co.isTransferHold(); _ck(_v, 26, 0, currVal_10); var currVal_11 = _co.isTransferJoined(); _ck(_v, 28, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getInitialConnectionState(); _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.getInitialConnectionNumber(); _ck(_v, 15, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v.parent, 1), _co.initialConnectionTimer)); _ck(_v, 17, 0, currVal_6); var currVal_9 = _co.getThirdPartyConnectionState(); _ck(_v, 24, 0, currVal_9); var currVal_12 = _co.getThirdPartyConnectionNumber(); _ck(_v, 33, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 35, 0, _ck(_v, 36, 0, i1.ɵnov(_v.parent, 1), _co.thirdPartyConnectionTimer)); _ck(_v, 35, 0, currVal_13); }); }
export function View_StatePanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.AgentStatusPipe, [i4.AmazonconnectService]), i1.ɵpid(0, i5.MillisToTimePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "state-panel"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatePanelComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "state-panel"; var currVal_1 = _co.getBackgroundColorClass(); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = !_co.isMultiPartyCall(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isMultiPartyCall(); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_StatePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-state-panel", [], null, null, null, View_StatePanelComponent_0, RenderType_StatePanelComponent)), i1.ɵdid(1, 114688, null, 0, i6.StatePanelComponent, [i4.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatePanelComponentNgFactory = i1.ɵccf("app-state-panel", i6.StatePanelComponent, View_StatePanelComponent_Host_0, { agentStatus: "agentStatus", connectAgent: "connectAgent" }, {}, []);
export { StatePanelComponentNgFactory as StatePanelComponentNgFactory };
