import { environment } from 'src/environments/environment';

export const getPopupUrl = (
  phoneNumber: string,
  agentId: string,
  contactId: string,
  queueName: string,
  initiationMethod: string,
): string => {
  return `${environment.popUpUrlDomain}/phone-call?agent-id=${agentId}&phone-number=${phoneNumber}&contact-id=${contactId}&queue-name=${queueName}&initiation-method=${initiationMethod}`
}
