import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


export interface SalesLogData {
    agentId: string;
    contactId: string;
    didNumber: string;
}

export interface SalesLogDataResponse {
    apiStatus: string;
    apiStatusCode: number;
}


@Injectable()
export class CaresService {

    headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders()
        .set('Authorization', environment.caresSalesUrlAuth);
    }

    logSalesCall(salesLogData: SalesLogData): Observable<SalesLogDataResponse>  {
        return this.http.post<SalesLogDataResponse>(environment.caresSalesUrl, salesLogData, {headers: this.headers});
    }
}
