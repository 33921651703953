/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../country-code-select/country-code-select.component.ngfactory";
import * as i3 from "../country-code-select/country-code-select.component";
import * as i4 from "../../services/amazonconnect.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./settings-menu.component";
import * as i8 from "../../services/ui-management.service";
var styles_SettingsMenuComponent = [i0.styles];
var RenderType_SettingsMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsMenuComponent, data: {} });
export { RenderType_SettingsMenuComponent as RenderType_SettingsMenuComponent };
function View_SettingsMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "settings__input-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-country-code-select", [], null, [[null, "selectedCountryEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedCountryEvent" === en)) {
        var pd_0 = (_co.setSelectedCountry($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CountryCodeSelectComponent_0, i2.RenderType_CountryCodeSelectComponent)), i1.ɵdid(2, 114688, null, 0, i3.CountryCodeSelectComponent, [i4.AmazonconnectService], { defaultCountry: [0, "defaultCountry"] }, { selectedCountryEvent: "selectedCountryEvent" }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "settings__input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "settings__number-input"], ["placeholder", "Enter a number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.validatePhone($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.phoneNumber.phoneNumber = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "settings__dial-btn"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateAgentExtension() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.phoneNumber == null) ? null : _co.phoneNumber.country); _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.phoneNumber.phoneNumber; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = (_co.phoneNumber.length === 0); var currVal_10 = !_co.isPhoneNumberValid; _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_SettingsMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "settings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "settings__phone-settings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "settings__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "settings__header-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Settings "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "settings__header-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "settings_phone-type"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Phone type"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["name", "softphone"], ["type", "radio"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSoftphone(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Softphone "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["name", "softphone"], ["type", "radio"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSoftphone(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Desk Phone "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsMenuComponent_1)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "settings__download-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadAgentLogs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download Logs"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.softphone; _ck(_v, 17, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.softphone; _ck(_v, 11, 0, currVal_0); var currVal_1 = !_co.softphone; _ck(_v, 14, 0, currVal_1); }); }
export function View_SettingsMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings-menu", [], null, null, null, View_SettingsMenuComponent_0, RenderType_SettingsMenuComponent)), i1.ɵdid(1, 114688, null, 0, i7.SettingsMenuComponent, [i4.AmazonconnectService, i8.UiManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsMenuComponentNgFactory = i1.ɵccf("app-settings-menu", i7.SettingsMenuComponent, View_SettingsMenuComponent_Host_0, {}, {}, []);
export { SettingsMenuComponentNgFactory as SettingsMenuComponentNgFactory };
