import axios from 'axios'
import { environment } from 'src/environments/environment'
import { agentUserName, contactId } from './amazonconnect.service'

type TSeverity = 'info' | 'warning' | 'error'

class Logger {
  private logData
  private severity: TSeverity
  private async log(): Promise<void> {
    if (!environment.splunkLogEndpoint || !environment.travelAgentsApiAuth) return
    try {
      await axios.post(environment.splunkLogEndpoint, {
        query: `
          query ForwardLogging($data: JSON!, $appName: NonEmptyString, $appVersion: NonEmptyString) {
            utility {
              forwardLogging(data: $data, appName: $appName, appVersion: $appVersion) {
                message
                code
              }
            }
          }
        `,
        variables: {
          data: this.logData,
          appName: 'sales-ccp',
        },
      }, {
        timeout: 5000,
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Basic ${environment.travelAgentsApiAuth}`,
          'Apollographql-Client-Name': 'sales-ccp',
        }
      });
    } catch (err) {
      console.error('Failed to send log to Splunk', err)
    }
  }

  private formatLogData(message: string, severity: TSeverity, params?: object): void {
    this.logData = {
      messages: message,
      severity: severity,
      environment: environment.production ? 'prod' : 'sandbox',
      ...(agentUserName && { agent: agentUserName }),
      ...(contactId && { contactId: contactId }),
      ...(params || {}),
    }
  }

  info(message: string, params?: object): void {
    this.formatLogData(message, 'info', params)
    this.log()
  }

  warning(message: string, params?: object): void {
    this.formatLogData(message, 'warning', params)
    this.log()
  }

  error(message: string, params?: object): void {
    this.formatLogData(message, 'error', params)
    this.log()
  }
}

const logger = new Logger()

export default logger
