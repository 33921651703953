/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./country-code-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./country-code-select.component";
import * as i4 from "../../services/amazonconnect.service";
var styles_CountryCodeSelectComponent = [i0.styles];
var RenderType_CountryCodeSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountryCodeSelectComponent, data: {} });
export { RenderType_CountryCodeSelectComponent as RenderType_CountryCodeSelectComponent };
function View_CountryCodeSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "fa material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["arrow_drop_up"]))], null, null); }
function View_CountryCodeSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "fa material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["arrow_drop_down"]))], null, null); }
function View_CountryCodeSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "country__selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedCountry(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "country__code-li"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "country__code-flag flag-icon flag-icon-", _v.context.$implicit.isoCode, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.code; _ck(_v, 4, 0, currVal_2); }); }
function View_CountryCodeSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "country__code-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountryCodeSelectComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countriesList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CountryCodeSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "country__code-dropdown"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "country__code"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountryCodeSelectComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountryCodeSelectComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountryCodeSelectComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.dropDownToggled; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.dropDownToggled; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.dropDownToggled; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "country__code-flag flag-icon flag-icon-", _co.selectedCountry.isoCode, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.selectedCountry.code; _ck(_v, 3, 0, currVal_1); }); }
export function View_CountryCodeSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-country-code-select", [], null, null, null, View_CountryCodeSelectComponent_0, RenderType_CountryCodeSelectComponent)), i1.ɵdid(1, 114688, null, 0, i3.CountryCodeSelectComponent, [i4.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountryCodeSelectComponentNgFactory = i1.ɵccf("app-country-code-select", i3.CountryCodeSelectComponent, View_CountryCodeSelectComponent_Host_0, { defaultCountry: "defaultCountry" }, { selectedCountryEvent: "selectedCountryEvent" }, []);
export { CountryCodeSelectComponentNgFactory as CountryCodeSelectComponentNgFactory };
