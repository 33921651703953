import axios from 'axios'
import { environment } from 'src/environments/environment'
import logger from './splunk.service'

export interface ICreatePhoneSessionParams {
  phoneNumber: string,
  contactId: string,
  phoneAgentId: string,
  isDynamic: boolean,
  ivrQueueName: string,
  initiationMethod: 'INBOUND' | 'OUTBOUND' | 'TRANSFER',
  customerPhoneNumber: string,
  isCallback: boolean | null,
  partnerCode: string,
}

export interface IIsCustomerCallbackParams {
  agentName: string,
  phoneNumber: string,
}

export interface IIsCustomerCallbackData {
  data?: {
    data?: {
      pcln?: {
        callback?: {
          isCustomerCallback?: boolean
        }
      }
    }
  },
  status?: number
}

export const createPhoneSession = async (params: ICreatePhoneSessionParams): Promise<void> => {
  if (!environment.travelAgentsApiEndpoint || !environment.travelAgentsApiAuth) return
  try {
    await axios.post(environment.travelAgentsApiEndpoint, {
      query: `
        mutation Create($phoneNumber: NonEmptyString!, $contactId: NonEmptyString!, $phoneAgentId: NonEmptyString!, $isDynamic: Boolean!, $ivrQueueName: String!, $initiationMethod: PhoneSessionInitiationMethod, $customerPhoneNumber: String, $isCallback: Boolean, $partnerCode: String) {
          pcln {
            phoneSession {
              create(phoneNumber: $phoneNumber, contactId: $contactId, phoneAgentId: $phoneAgentId, isDynamic: $isDynamic, ivrQueueName: $ivrQueueName, initiationMethod: $initiationMethod, customerPhoneNumber: $customerPhoneNumber, isCallback: $isCallback, partnerCode: $partnerCode) {
                id
              }
            }
          }
        }
      `,
      variables: {
        phoneNumber: params.phoneNumber,
        contactId: params.contactId,
        phoneAgentId: params.phoneAgentId,
        isDynamic: params.isDynamic,
        ivrQueueName: params.ivrQueueName,
        initiationMethod: params.initiationMethod,
        customerPhoneNumber: params.customerPhoneNumber,
        isCallback: params.isCallback,
        partnerCode: params.partnerCode,
      },
    }, {
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Basic ${environment.travelAgentsApiAuth}`,
        'Apollographql-Client-Name': 'sales-ccp',
      }
    })
  } catch (err) {
    logger.error('Failed to create phone session', {
      err
    })
  }
}

export const isCallbackByPhoneNumber = async (params: IIsCustomerCallbackParams): Promise<IIsCustomerCallbackData> => {
  if (!environment.travelAgentsApiEndpoint || !environment.travelAgentsApiAuth) return
  try {
    return await axios.post(environment.travelAgentsApiEndpoint, {
      query: `
        query Callback($agentUserName: String!, $phoneNumber: String!) {
          pcln {
            callback {
              isCustomerCallback(agentUserName: $agentUserName, phoneNumber: $phoneNumber)
            }
          }
        }
      `,
      variables: {
        phoneNumber: params.phoneNumber,
        agentUserName: params.agentName
      },
    }, {
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Basic ${environment.travelAgentsApiAuth}`,
        'Apollographql-Client-Name': 'sales-ccp',
      }
    })
  } catch (err) {
    logger.error('Failed to check if callback', {
      err
    })
  }
}
