/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./actions-panel.component";
import * as i4 from "../../services/ui-management.service";
import * as i5 from "../../services/amazonconnect.service";
var styles_ActionsPanelComponent = [i0.styles];
var RenderType_ActionsPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionsPanelComponent, data: {} });
export { RenderType_ActionsPanelComponent as RenderType_ActionsPanelComponent };
function View_ActionsPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "actions-panel__dial-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNumberDialer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dialpad"])), (_l()(), i1.ɵted(-1, null, ["Dial number"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openQuickConnects() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["contacts"])), (_l()(), i1.ɵted(-1, null, ["Quick connects"]))], null, null); }
function View_ActionsPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"])), (_l()(), i1.ɵted(-1, null, ["Hold"]))], null, null); }
function View_ActionsPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["play_arrow"])), (_l()(), i1.ɵted(-1, null, ["Resume"]))], null, null); }
function View_ActionsPanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"])), (_l()(), i1.ɵted(-1, null, ["Mute"]))], null, null); }
function View_ActionsPanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic"])), (_l()(), i1.ɵted(-1, null, ["Unmute"]))], null, null); }
function View_ActionsPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.amazonconnectService.isAgentMuted(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.amazonconnectService.isAgentMuted(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionsPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "actions-panel__call-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNumberDialer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dialpad"])), (_l()(), i1.ɵted(-1, null, ["Dial number"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openQuickConnects() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["phone_forwarded"])), (_l()(), i1.ɵted(-1, null, ["Transfer"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.onHold(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.onHold(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isSoftphone(); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ActionsPanelComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdThirdPartyConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"])), (_l()(), i1.ɵted(-1, null, ["Hold"]))], null, null); }
function View_ActionsPanelComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumeThirdPartyConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["play_arrow"])), (_l()(), i1.ɵted(-1, null, ["Resume"]))], null, null); }
function View_ActionsPanelComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"])), (_l()(), i1.ɵted(-1, null, ["Mute"]))], null, null); }
function View_ActionsPanelComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic"])), (_l()(), i1.ɵted(-1, null, ["Unmute"]))], null, null); }
function View_ActionsPanelComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_12)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_13)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.amazonconnectService.isAgentMuted(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.amazonconnectService.isAgentMuted(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionsPanelComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "actions-panel__call-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_10)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_11)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNumberDialer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dialpad"])), (_l()(), i1.ɵted(-1, null, ["Dial number"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.onHold(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.onHold(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isSoftphone(); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ActionsPanelComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"])), (_l()(), i1.ɵted(-1, null, ["Mute"]))], null, null); }
function View_ActionsPanelComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic"])), (_l()(), i1.ɵted(-1, null, ["Unmute"]))], null, null); }
function View_ActionsPanelComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_16)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_17)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.amazonconnectService.isAgentMuted(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.amazonconnectService.isAgentMuted(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionsPanelComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "actions-panel__transfer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.joinConnections() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "fa material-icons md-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["call_merge"])), (_l()(), i1.ɵted(-1, null, ["Join"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleActionConnections() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "fa material-icons md-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["swap_calls"])), (_l()(), i1.ɵted(-1, null, ["Swap"])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNumberDialer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dialpad"])), (_l()(), i1.ɵted(-1, null, ["Dial number"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdAllConnections() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"])), (_l()(), i1.ɵted(-1, null, ["Hold all"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_15)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSoftphone(); _ck(_v, 21, 0, currVal_0); }, null); }
function View_ActionsPanelComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"])), (_l()(), i1.ɵted(-1, null, ["Mute"]))], null, null); }
function View_ActionsPanelComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic"])), (_l()(), i1.ɵted(-1, null, ["Unmute"]))], null, null); }
function View_ActionsPanelComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_20)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_21)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.amazonconnectService.isAgentMuted(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.amazonconnectService.isAgentMuted(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionsPanelComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "actions-panel__transfer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumeAllConnections() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "fa material-icons md-18"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["play_arrow"])), (_l()(), i1.ɵted(-1, null, ["Resume All"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_19)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSoftphone(); _ck(_v, 8, 0, currVal_0); }, null); }
function View_ActionsPanelComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic_off"])), (_l()(), i1.ɵted(-1, null, ["Mute"]))], null, null); }
function View_ActionsPanelComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.muteConnection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa material-icons md-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mic"])), (_l()(), i1.ɵted(-1, null, ["Unmute"]))], null, null); }
function View_ActionsPanelComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_24)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_25)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.amazonconnectService.isAgentMuted(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.amazonconnectService.isAgentMuted(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionsPanelComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "actions-panel__transfer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNumberDialer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dialpad"])), (_l()(), i1.ɵted(-1, null, ["Dial number"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "actions-panel__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.holdAllConnections() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "fa material-icons md-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pause"])), (_l()(), i1.ɵted(-1, null, ["Hold all"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "actions-panel__btn-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_23)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSoftphone(); _ck(_v, 12, 0, currVal_0); }, null); }
export function View_ActionsPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "actions-panel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_14)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_18)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsPanelComponent_22)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDialBtns(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayConnectedActions(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.displayConnectedThirdPartyActions(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.displayTransferActions(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.displayTransferHoldActions(); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.displayTransferJoinedActions(); _ck(_v, 12, 0, currVal_5); }, null); }
export function View_ActionsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-actions-panel", [], null, null, null, View_ActionsPanelComponent_0, RenderType_ActionsPanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.ActionsPanelComponent, [i4.UiManagementService, i5.AmazonconnectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionsPanelComponentNgFactory = i1.ɵccf("app-actions-panel", i3.ActionsPanelComponent, View_ActionsPanelComponent_Host_0, { agentStatus: "agentStatus" }, {}, []);
export { ActionsPanelComponentNgFactory as ActionsPanelComponentNgFactory };
