export const environment = {
  production: false,
  ccpUrl: "https://pricelinedev.my.connect.aws/connect/ccp#/",
  samlSsoUrl: "https://pricelinedev.my.connect.aws/connect/login",
  ccpLogoutUrl: "https://pricelinedev.my.connect.aws/connect/logout",
  popUpUrlDomain:
    "https://agents-qaa.pricelinepartnersolutions.com/travel-agents-api",
  caresSalesUrl:
    "https://api-green.g.pclncloud.com/ivr-connect-service/v1/sales-call/log",
  caresSalesUrlAuth: "ybBgoQ3DauRfIFS15Au3bAC4XMglTM0q",
  travelAgentsApiAuth:
    "cHJpY2VsaW5lLXRlbXAtY2xpZW50LWlkOmYxZjk3MzUxODIyMDNlZjIxODQzNGE4YjdhMzVhZmI2ZDE4ZWQ0ZTAwMDRlMDI5NDAzMTFkNmVjOTRmMTJlOWE=",
  travelAgentsApiEndpoint:
    "https://agents-qaa.pricelinepartnersolutions.com/travel-agents-api/graphql",
  splunkLogEndpoint:
    "https://agents-qaa.pricelinepartnersolutions.com/travel-agents-api/graphql",
};
