/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./top-panel.component";
import * as i4 from "../../services/amazonconnect.service";
import * as i5 from "../../services/ui-management.service";
var styles_TopPanelComponent = [i0.styles];
var RenderType_TopPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopPanelComponent, data: {} });
export { RenderType_TopPanelComponent as RenderType_TopPanelComponent };
function View_TopPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["expand_less"]))], null, null); }
function View_TopPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["expand_more"]))], null, null); }
function View_TopPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "top-panel-dropdown__option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeAgentState(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "top-panel-dropdown__option-selected": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["check"])), (_l()(), i1.ɵted(6, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top-panel-dropdown__option"; var currVal_1 = _ck(_v, 3, 0, (_co.agentStatus.name === _v.context.$implicit.name)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_TopPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "top-panel-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "top-panel__dropdown-opts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopPanelComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "top-panel__dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "top-panel__logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Log out, "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableAgentStates; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.agentUserName; _ck(_v, 8, 0, currVal_1); }); }
export function View_TopPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "top-panel"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "i", [["class", "top-panel__cloud material-icons md-light md-24"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "color-text-blue": 0 }), (_l()(), i1.ɵted(-1, null, ["cloud"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "top-panel__dropdown-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "color-text-blue": 0, "disabled": 1 }), (_l()(), i1.ɵted(-1, null, [" Change status "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopPanelComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopPanelComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "top-panel__settings-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSettingsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(19, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "no-border": 0 }), (_l()(), i1.ɵeld(21, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["settings"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopPanelComponent_3)), i1.ɵdid(24, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top-panel"; var currVal_1 = _co.getBackgroundColorClass(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "top-panel__cloud material-icons md-light md-24"; var currVal_3 = _ck(_v, 6, 0, _co.dropdownToggled); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = "top-panel__dropdown-btn"; var currVal_5 = _ck(_v, 11, 0, _co.dropdownToggled, _co.isAgentBusy()); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = _co.dropdownToggled; _ck(_v, 14, 0, currVal_6); var currVal_7 = !_co.dropdownToggled; _ck(_v, 16, 0, currVal_7); var currVal_8 = "top-panel__settings-btn"; var currVal_9 = _ck(_v, 20, 0, _co.dropdownToggled); _ck(_v, 19, 0, currVal_8, currVal_9); var currVal_10 = _co.dropdownToggled; _ck(_v, 24, 0, currVal_10); }, null); }
export function View_TopPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-panel", [], null, null, null, View_TopPanelComponent_0, RenderType_TopPanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopPanelComponent, [i4.AmazonconnectService, i5.UiManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopPanelComponentNgFactory = i1.ɵccf("app-top-panel", i3.TopPanelComponent, View_TopPanelComponent_Host_0, { availableAgentStates: "availableAgentStates", agentStatus: "agentStatus" }, {}, []);
export { TopPanelComponentNgFactory as TopPanelComponentNgFactory };
