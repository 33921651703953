var _this = this;
import * as tslib_1 from "tslib";
import axios from 'axios';
import { environment } from 'src/environments/environment';
import logger from './splunk.service';
export var createPhoneSession = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var err_1;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!environment.travelAgentsApiEndpoint || !environment.travelAgentsApiAuth)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post(environment.travelAgentsApiEndpoint, {
                        query: "\n        mutation Create($phoneNumber: NonEmptyString!, $contactId: NonEmptyString!, $phoneAgentId: NonEmptyString!, $isDynamic: Boolean!, $ivrQueueName: String!, $initiationMethod: PhoneSessionInitiationMethod, $customerPhoneNumber: String, $isCallback: Boolean, $partnerCode: String) {\n          pcln {\n            phoneSession {\n              create(phoneNumber: $phoneNumber, contactId: $contactId, phoneAgentId: $phoneAgentId, isDynamic: $isDynamic, ivrQueueName: $ivrQueueName, initiationMethod: $initiationMethod, customerPhoneNumber: $customerPhoneNumber, isCallback: $isCallback, partnerCode: $partnerCode) {\n                id\n              }\n            }\n          }\n        }\n      ",
                        variables: {
                            phoneNumber: params.phoneNumber,
                            contactId: params.contactId,
                            phoneAgentId: params.phoneAgentId,
                            isDynamic: params.isDynamic,
                            ivrQueueName: params.ivrQueueName,
                            initiationMethod: params.initiationMethod,
                            customerPhoneNumber: params.customerPhoneNumber,
                            isCallback: params.isCallback,
                            partnerCode: params.partnerCode,
                        },
                    }, {
                        timeout: 5000,
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': "Basic " + environment.travelAgentsApiAuth,
                            'Apollographql-Client-Name': 'sales-ccp',
                        }
                    })];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                logger.error('Failed to create phone session', {
                    err: err_1
                });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var isCallbackByPhoneNumber = function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var err_2;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!environment.travelAgentsApiEndpoint || !environment.travelAgentsApiAuth)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post(environment.travelAgentsApiEndpoint, {
                        query: "\n        query Callback($agentUserName: String!, $phoneNumber: String!) {\n          pcln {\n            callback {\n              isCustomerCallback(agentUserName: $agentUserName, phoneNumber: $phoneNumber)\n            }\n          }\n        }\n      ",
                        variables: {
                            phoneNumber: params.phoneNumber,
                            agentUserName: params.agentName
                        },
                    }, {
                        timeout: 5000,
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': "Basic " + environment.travelAgentsApiAuth,
                            'Apollographql-Client-Name': 'sales-ccp',
                        }
                    })];
            case 2: return [2 /*return*/, _a.sent()];
            case 3:
                err_2 = _a.sent();
                logger.error('Failed to check if callback', {
                    err: err_2
                });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
